.game-fullscreen-button {
    color: #fff;
    font-size: 4vw;
    position: absolute;
    right: 20px;
    bottom: 25px;
    z-index: 10;
    cursor: pointer;
    display: block;

    &.isFullscreen {
        display: none;
    }

    @media (max-width: 1024px) {

        &.isFullscreen {
            display: block;
        }
    }
}
