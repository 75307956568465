@import "_variables.scss";


/* fonts ---------------------- */

@font-face {
  font-family: 'Cube Regular';
  src: url('./fonts/thecube2020us-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* ---------------------------- */


body {
  margin: 0;
  padding: 0;
  font-family: "Arial", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-black;
}

