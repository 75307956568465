.pwResetView {
    .ui-content {
        padding: 40px 80px !important;
        
        .buttonBar {
            button {
                width: 50%;
                display: inline-block;
            }
        }
    }
}