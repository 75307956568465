@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
@import '../../variables.scss';

/* styles from tbs theme we need to replicate locally */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


/* override tbs wrapper classes */
body{
    background-color: #000 !important;
}

header {
    display: none !important;
}

#wrap {
    background-image: none !important;
    background-color: #000 !important;
}

footer {
    background-image: none !important;
    background-color: #000 !important;
}

.footer-app-widget {
    display: none !important;
}

.footer-social {
    display: none !important;
}

#toast-container.toast-term-privacy .toast-message {
    font-size: 15px !important;
    font-family: $font-exo !important;
}

#block-ten-tenfooter {
    background-color: #000!important;
}

/* hide tbs footer in fullscreen */
.game-fullscreen footer {
    display: none;
}
/* end tbs overrides */


/* facebook app popup */

.fbpopupwrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    z-index: 99999;

    .fbpopup {
        color: white;
        padding: 16px;
        position: absolute;
        width: 200px;
        top: 20vh;
        right: calc(50vw - 100px);
        background-color: black;
        border: #fff 1px solid;
        z-index: 99999;

        .fbarrow {
            color: white;
            font-size: 34px;
            font-family: sans-serif;
            float: right;
            position: absolute;
            right: 4px;
            bottom: 4px;

            img {
                height: 45px;
                width: auto;
            }
        }
    }
}

.facebookapp {
    .fbpopupwrapper {
        display: block;
    }
}


.game-fullscreen #wrapper {
    min-height: 100%;
    max-height: 100%;

    .game-wrapper {
        max-width: 100%;
        max-height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        @media (max-width: 1024px) {
            max-height: 100%;
        }
        
        @media handheld and (orientation: landscape) {
            max-width: 100%;
            max-height: 100%;

            .game-canvas {
                max-height: 100%;
            }

        }
    }

    .cube-header {
        display: none;
    }
}

.portrait {
    .ui {
        display: none;
    }

    @media (max-width: 1024px) {
        .game-wrapper {
            height: 60vh !important;
            max-height: none !important;
        }
    }
}

#branch-banner-iframe {
    display: none;
}

#wrapper {
    text-align: center;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    font-family: Exo,Arial,Helvetica,sans-serif;

    .game-wrapper {
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 70vw;
        max-height: 40vw;
        margin: 0 auto;
        height: 100%;
        
        @media (max-width: 1024px) {
            margin: 0 auto;
            width: 100%;
            max-width: 80vw;
            height: 90vh;
            max-height: 54vw;
        }

        // @media (max-width: 1024px) {
        //     max-width: 100vw;
        // }
        
        @media handheld and (orientation: landscape) {
            max-width: 60vw;
            max-height: 40vh;
        }
    }
        
    .cube-header {
        position: relative;
        height: 70px;
        user-select: none;
        
        @media (max-width: 1023px) and (orientation: landscape) {
            height: 60px;
        }
        
        .tbs-logo {
            position: absolute;
            top: 12px;
            left: 16px;
            
            img {
                max-width: 70px;
            }
            
            @media (max-width: 1023px) and (orientation: landscape) {
                img {
                    max-width: 50px;
                }
            }
        }

        .watch-the-cube {
            position: absolute;
            right: 16px;
            top: 18px;
    
            a {
                font-family: $font-exo;
                text-decoration: none;
                color: $color-white;
                font-size: 24px;
    
                @media (max-width: 1023px) and (orientation: landscape) {
                    font-size: 20px;
                }
    
                &:visited {
                    color: $color-white;
                }
            }
        }
    }


    .game-canvas {
        width: 100%;
        height: 100%;
    }
    
    .game-fullscreen {
        width: 100vw;
        max-width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;        
        height: 100%;
        max-height: 100%;
    }
}

.game-fullscreen {
    #wrapper {
        .ui {
            top: -20px;
            max-width: 100%;
            padding-top: 0;

            .ui-content {
                padding-top: 20px;

                @media (min-width: 1024px) {
                    padding-top: 100px;
                }
            }

            .closeButton {
                top: 45px;
            }
        }
    }

    .legal {
        display: none;
    }
}


.ui {
    display: none;
    width: 100%;
    height: 100%;
    top: 60px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    color: $color-white;
    background-color: $color-black;
    font-size: 24px;
    z-index: 20;
    padding-top: 70px;
    max-width: 70vw;

    @include tablet {
        left: 0;
        max-width: 100%;
        padding-top: 20px;
        font-size: 14px;
    }

    .ui-content {
        padding: 0px 40px;

        @include tablet {
            padding: 0px 20px;
        }
    }

    h2 {
        font-family: $font-cube;
        font-size: 32px;
        margin: 0 0 20px 0;
        font-weight: normal;

        @include tablet {
            font-size: 20px;
        }
    }

    &.active {
        display: block;
    }

    .closeButton {
        width: 4.5vw;
        height: 4.5vw;
        max-width: 90px;
        max-height: 90px;
        left: 30px;
        top: 47px;
        cursor: pointer;
        position: absolute;
        
        img {
            width: 100%;
            height: auto;
        }

        @include tablet {
            width: 38px;
            height: 38px;
            left: 24px;
            top: 24px;
        }
    }

    input {
        margin-bottom: 10px;
    }

    .button {
        width: 100%;
        height: 80px;
        box-shadow: 0 0 10px 0 #0066ff;
        border: solid 2px #0066ff;
        background-color: rgba(0, 35, 89, 0.92);
        color: $color-white;
        font-family: $font-cube;
        font-size: 28px;
        cursor: pointer;

        @include tablet {
            height: 50px;
            font-size: 20px;
            line-height: 22px;
        }
    }

    .button:disabled {
        opacity: 0.5;
    }
}

.legal {
    user-select: none;
    color: #808080;
    text-align: center;
    padding: 32px;

    a {
        color: #808080;
        text-decoration: underline!important;
    }
}

// fixing Chrome autocomplete colors a bit
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px rgba(0,0,0,0.5) inset;
    color: white!important;
}
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 50px rgba(0,0,0,0.5) inset;
    color: white!important;
}

