@import "../../_variables";

.loader {
    position: relative;
    top: 150px;
    left: 25%;
    width: 50%;
    color: #fff;
    height: 120px;

    @media (orientation: portrait) {
        top: 20px;
    }

    &.hidden {
        display: none;
    }

    .progressContainer {
        margin-top: 16px;
        width: 100%;
        height: 21px;
        border: $color-white 1px solid;
        position: relative;

        .progressBar {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-white;
            height: 20px;
        }
    }
}
