@import "../../variables";

.forgotPassword {

    .ui-content {
            
        @include tablet {
            padding-top: 40px !important;
        }
    }

    .wrapper {
        max-width: 800px;
        margin: 0 auto;
        padding: 0 80px;
    }

    h2 {
        font-family: $font-cube;
        font-weight: normal;
    }
    .buttonBar {
        display: flex;
        flex-direction: row;
    }

    button.backButton {
        flex: 1 1 40%;
    }

    button.submitButton {
        flex: 1 1 60%;
        margin-left: 1%;

        &:disabled {
            opacity: 0.5;
        }
    }

    .statusBlock {
        width: 100%;
        margin: 16px auto;
        font-size: 18px;

        @include tablet {
            font-size: 14px;
        }

        .statusText {
            width: 80%;
            margin: 0 auto;
            margin: 16px auto;

            &.highlight {
                color: $color-lime;
            }
        }
    }
}
