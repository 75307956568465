$color-black : #000;
$color-white : #fff;
$color-red: red;
$color-blue: #0066ff;
$color-lime: #00ff91;

$font-exo: "Exo", "Arial", "Helvetica", sans-serif;
$font-cube: "Cube Regular", "Arial", "Helvetica", sans-serif;

$mobile-max: 767px;
$tablet-max: 1023px;

@mixin mobile {
    @media (max-width: $mobile-max) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet-max) {
        @content;
    }
}
