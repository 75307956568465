@import "../../_variables.scss";

.create-account {
    width: 100%;
    display: flex;
    flex-direction: row;

    .twoCol {
        flex: 1 1 50%;
        margin: 0;
        padding: 16px;
        max-width: 50%;

        .errorMessage {
            font-size: 16px;
            color: #ff0000;
            min-height: 20px;

            @include tablet {
                min-height: 15px;
                font-size: 13px;
                line-height: 15px;
                margin: 12px 0;
            }
        }
    }
}
