@import "../../variables";

.accountTabs {

    .ui-content {
        padding: 0 !important;
    }

    .tabRow {
        position: relative;
        width: 80%;
        left: 10%;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        height: 40px;

        .tabName {
            flex: 1 1;
            text-align: center;
            cursor: pointer;
            border-bottom: rgba(255, 255, 255, 0.25) 2px solid;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 0px;

            @include tablet {
                font-size: 16px;
                line-height: 40px;
            }

            &.active {
                border-bottom: #0066ff 4px solid;
            }
        }
    }

    .tab {
        display: none;
        padding: 8px 16px;
        text-align: center;
        margin: 0 auto;

        &.active {
            display: block;
        }
    }
}
