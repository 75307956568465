@import "../../_variables.scss";

.inputBox {
    text-align: left;
    position: relative;

    label {
        display: block;
        text-align: left;
        position: absolute;
        left: 8px;
        top: 8px;
        font-size: 20px;
        font-family: $font-exo;

        &.errorLabel {
            color: red;
        }

        @include tablet {
            font-size: 13px;
            line-height: 15px;
        }
    }

    input {
        width: 100%;
        height: 80px;
        padding: 40px 6px 6px 6px;
        font-size: 24px;
        font-family: $font-exo;
        color: $color-white;
        border: solid 2px rgba(255, 255, 255, 0.3);
        background-color: rgba(0, 0, 0, 0.6);

        &.error {
            border-color: $color-red;
        }

        @include tablet {
            padding: 24px 6px 6px 6px;
            height: 50px;
            font-size: 13px;
            line-height: 15px;
        }
    }
}
