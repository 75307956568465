@import "../../_variables.scss";

.pwChangeView {

    .ui-content {
        padding: 20px 80px !important;
    }
    .error {
        color: $color-red;
    }
}
