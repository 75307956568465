@import url(https://fonts.googleapis.com/css2?family=Exo&display=swap);
/* fonts ---------------------- */
@font-face {
  font-family: 'Cube Regular';
  src: url(https://i.cdn.tbs.com/spa/the-cube-game/1.13.1/static/media/thecube2020us-regular-webfont.13cd5b69.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

/* ---------------------------- */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000; }

.game-fullscreen-button {
  color: #fff;
  font-size: 4vw;
  position: absolute;
  right: 20px;
  bottom: 25px;
  z-index: 10;
  cursor: pointer;
  display: block; }
  .game-fullscreen-button.isFullscreen {
    display: none; }
  @media (max-width: 1024px) {
    .game-fullscreen-button.isFullscreen {
      display: block; } }

.loader {
  position: relative;
  top: 150px;
  left: 25%;
  width: 50%;
  color: #fff;
  height: 120px; }
  @media (orientation: portrait) {
    .loader {
      top: 20px; } }
  .loader.hidden {
    display: none; }
  .loader .progressContainer {
    margin-top: 16px;
    width: 100%;
    height: 21px;
    border: #fff 1px solid;
    position: relative; }
    .loader .progressContainer .progressBar {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      height: 20px; }

.accountTabs .ui-content {
  padding: 0 !important; }

.accountTabs .tabRow {
  position: relative;
  width: 80%;
  left: 10%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  height: 40px; }
  .accountTabs .tabRow .tabName {
    flex: 1 1;
    text-align: center;
    cursor: pointer;
    border-bottom: rgba(255, 255, 255, 0.25) 2px solid;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 0px; }
    @media (max-width: 1023px) {
      .accountTabs .tabRow .tabName {
        font-size: 16px;
        line-height: 40px; } }
    .accountTabs .tabRow .tabName.active {
      border-bottom: #0066ff 4px solid; }

.accountTabs .tab {
  display: none;
  padding: 8px 16px;
  text-align: center;
  margin: 0 auto; }
  .accountTabs .tab.active {
    display: block; }

.login {
  max-width: 600px;
  margin: 20px auto 0px auto;
  padding: 0px 80px; }
  .login h2 {
    font-family: 'Cube Regular', 'Exo', 'sans-serif';
    font-weight: 100;
    font-size: 22px; }
  .login .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    cursor: pointer; }
  .login .forgotPassword {
    margin-top: 10px;
    color: #0066ff;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold; }
  .login .error {
    color: red; }

.inputBox {
  text-align: left;
  position: relative; }
  .inputBox label {
    display: block;
    text-align: left;
    position: absolute;
    left: 8px;
    top: 8px;
    font-size: 20px;
    font-family: "Exo", "Arial", "Helvetica", sans-serif; }
    .inputBox label.errorLabel {
      color: red; }
    @media (max-width: 1023px) {
      .inputBox label {
        font-size: 13px;
        line-height: 15px; } }
  .inputBox input {
    width: 100%;
    height: 80px;
    padding: 40px 6px 6px 6px;
    font-size: 24px;
    font-family: "Exo", "Arial", "Helvetica", sans-serif;
    color: #fff;
    border: solid 2px rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.6); }
    .inputBox input.error {
      border-color: red; }
    @media (max-width: 1023px) {
      .inputBox input {
        padding: 24px 6px 6px 6px;
        height: 50px;
        font-size: 13px;
        line-height: 15px; } }

.create-account {
  width: 100%;
  display: flex;
  flex-direction: row; }
  .create-account .twoCol {
    flex: 1 1 50%;
    margin: 0;
    padding: 16px;
    max-width: 50%; }
    .create-account .twoCol .errorMessage {
      font-size: 16px;
      color: #ff0000;
      min-height: 20px; }
      @media (max-width: 1023px) {
        .create-account .twoCol .errorMessage {
          min-height: 15px;
          font-size: 13px;
          line-height: 15px;
          margin: 12px 0; } }

.checkBox {
  text-align: left;
  margin-bottom: 32px;
  display: table; }
  @media (max-width: 1023px) {
    .checkBox {
      margin-bottom: 0px; } }
  .checkBox input {
    display: table-cell;
    margin-right: 16px;
    height: 32px;
    width: 32px;
    position: relative;
    top: 8px; }
    @media (max-width: 1023px) {
      .checkBox input {
        margin-right: 8px;
        height: 20px;
        width: 20px;
        top: 6px; } }
  .checkBox label {
    font-size: 20px;
    display: table-cell; }
    .checkBox label a {
      color: #0066ff; }
    @media (max-width: 1023px) {
      .checkBox label {
        font-size: 13px;
        line-height: 15px; } }

@media (max-width: 1023px) {
  .forgotPassword .ui-content {
    padding-top: 40px !important; } }

.forgotPassword .wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 80px; }

.forgotPassword h2 {
  font-family: "Cube Regular", "Arial", "Helvetica", sans-serif;
  font-weight: normal; }

.forgotPassword .buttonBar {
  display: flex;
  flex-direction: row; }

.forgotPassword button.backButton {
  flex: 1 1 40%; }

.forgotPassword button.submitButton {
  flex: 1 1 60%;
  margin-left: 1%; }
  .forgotPassword button.submitButton:disabled {
    opacity: 0.5; }

.forgotPassword .statusBlock {
  width: 100%;
  margin: 16px auto;
  font-size: 18px; }
  @media (max-width: 1023px) {
    .forgotPassword .statusBlock {
      font-size: 14px; } }
  .forgotPassword .statusBlock .statusText {
    width: 80%;
    margin: 0 auto;
    margin: 16px auto; }
    .forgotPassword .statusBlock .statusText.highlight {
      color: #00ff91; }

.loginSuccess .ui-content {
  padding: 40px 80px !important; }

.logoutSuccess .ui-content {
  padding: 40px 80px !important; }

.pwResetSuccess .ui-content {
  padding: 40px 80px !important; }

.pwResetView .ui-content {
  padding: 40px 80px !important; }
  .pwResetView .ui-content .buttonBar button {
    width: 50%;
    display: inline-block; }

.pwChangeView .ui-content {
  padding: 20px 80px !important; }

.pwChangeView .error {
  color: red; }

/* styles from tbs theme we need to replicate locally */
* {
  box-sizing: border-box; }

/* override tbs wrapper classes */
body {
  background-color: #000 !important; }

header {
  display: none !important; }

#wrap {
  background-image: none !important;
  background-color: #000 !important; }

footer {
  background-image: none !important;
  background-color: #000 !important; }

.footer-app-widget {
  display: none !important; }

.footer-social {
  display: none !important; }

#toast-container.toast-term-privacy .toast-message {
  font-size: 15px !important;
  font-family: "Exo", "Arial", "Helvetica", sans-serif !important; }

#block-ten-tenfooter {
  background-color: #000 !important; }

/* hide tbs footer in fullscreen */
.game-fullscreen footer {
  display: none; }

/* end tbs overrides */
/* facebook app popup */
.fbpopupwrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999; }
  .fbpopupwrapper .fbpopup {
    color: white;
    padding: 16px;
    position: absolute;
    width: 200px;
    top: 20vh;
    right: calc(50vw - 100px);
    background-color: black;
    border: #fff 1px solid;
    z-index: 99999; }
    .fbpopupwrapper .fbpopup .fbarrow {
      color: white;
      font-size: 34px;
      font-family: sans-serif;
      float: right;
      position: absolute;
      right: 4px;
      bottom: 4px; }
      .fbpopupwrapper .fbpopup .fbarrow img {
        height: 45px;
        width: auto; }

.facebookapp .fbpopupwrapper {
  display: block; }

.game-fullscreen #wrapper {
  min-height: 100%;
  max-height: 100%; }
  .game-fullscreen #wrapper .game-wrapper {
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    @media (max-width: 1024px) {
      .game-fullscreen #wrapper .game-wrapper {
        max-height: 100%; } }
    @media handheld and (orientation: landscape) {
      .game-fullscreen #wrapper .game-wrapper {
        max-width: 100%;
        max-height: 100%; }
        .game-fullscreen #wrapper .game-wrapper .game-canvas {
          max-height: 100%; } }
  .game-fullscreen #wrapper .cube-header {
    display: none; }

.portrait .ui {
  display: none; }

@media (max-width: 1024px) {
  .portrait .game-wrapper {
    height: 60vh !important;
    max-height: none !important; } }

#branch-banner-iframe {
  display: none; }

#wrapper {
  text-align: center;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  font-family: Exo,Arial,Helvetica,sans-serif; }
  #wrapper .game-wrapper {
    position: relative;
    padding: 0;
    width: 100%;
    max-width: 70vw;
    max-height: 40vw;
    margin: 0 auto;
    height: 100%; }
    @media (max-width: 1024px) {
      #wrapper .game-wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 80vw;
        height: 90vh;
        max-height: 54vw; } }
    @media handheld and (orientation: landscape) {
      #wrapper .game-wrapper {
        max-width: 60vw;
        max-height: 40vh; } }
  #wrapper .cube-header {
    position: relative;
    height: 70px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    @media (max-width: 1023px) and (orientation: landscape) {
      #wrapper .cube-header {
        height: 60px; } }
    #wrapper .cube-header .tbs-logo {
      position: absolute;
      top: 12px;
      left: 16px; }
      #wrapper .cube-header .tbs-logo img {
        max-width: 70px; }
      @media (max-width: 1023px) and (orientation: landscape) {
        #wrapper .cube-header .tbs-logo img {
          max-width: 50px; } }
    #wrapper .cube-header .watch-the-cube {
      position: absolute;
      right: 16px;
      top: 18px; }
      #wrapper .cube-header .watch-the-cube a {
        font-family: "Exo", "Arial", "Helvetica", sans-serif;
        text-decoration: none;
        color: #fff;
        font-size: 24px; }
        @media (max-width: 1023px) and (orientation: landscape) {
          #wrapper .cube-header .watch-the-cube a {
            font-size: 20px; } }
        #wrapper .cube-header .watch-the-cube a:visited {
          color: #fff; }
  #wrapper .game-canvas {
    width: 100%;
    height: 100%; }
  #wrapper .game-fullscreen {
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 100%; }

.game-fullscreen #wrapper .ui {
  top: -20px;
  max-width: 100%;
  padding-top: 0; }
  .game-fullscreen #wrapper .ui .ui-content {
    padding-top: 20px; }
    @media (min-width: 1024px) {
      .game-fullscreen #wrapper .ui .ui-content {
        padding-top: 100px; } }
  .game-fullscreen #wrapper .ui .closeButton {
    top: 45px; }

.game-fullscreen .legal {
  display: none; }

.ui {
  display: none;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #fff;
  background-color: #000;
  font-size: 24px;
  z-index: 20;
  padding-top: 70px;
  max-width: 70vw; }
  @media (max-width: 1023px) {
    .ui {
      left: 0;
      max-width: 100%;
      padding-top: 20px;
      font-size: 14px; } }
  .ui .ui-content {
    padding: 0px 40px; }
    @media (max-width: 1023px) {
      .ui .ui-content {
        padding: 0px 20px; } }
  .ui h2 {
    font-family: "Cube Regular", "Arial", "Helvetica", sans-serif;
    font-size: 32px;
    margin: 0 0 20px 0;
    font-weight: normal; }
    @media (max-width: 1023px) {
      .ui h2 {
        font-size: 20px; } }
  .ui.active {
    display: block; }
  .ui .closeButton {
    width: 4.5vw;
    height: 4.5vw;
    max-width: 90px;
    max-height: 90px;
    left: 30px;
    top: 47px;
    cursor: pointer;
    position: absolute; }
    .ui .closeButton img {
      width: 100%;
      height: auto; }
    @media (max-width: 1023px) {
      .ui .closeButton {
        width: 38px;
        height: 38px;
        left: 24px;
        top: 24px; } }
  .ui input {
    margin-bottom: 10px; }
  .ui .button {
    width: 100%;
    height: 80px;
    box-shadow: 0 0 10px 0 #0066ff;
    border: solid 2px #0066ff;
    background-color: rgba(0, 35, 89, 0.92);
    color: #fff;
    font-family: "Cube Regular", "Arial", "Helvetica", sans-serif;
    font-size: 28px;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .ui .button {
        height: 50px;
        font-size: 20px;
        line-height: 22px; } }
  .ui .button:disabled {
    opacity: 0.5; }

.legal {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #808080;
  text-align: center;
  padding: 32px; }
  .legal a {
    color: #808080;
    text-decoration: underline !important; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0.5) inset;
  color: white !important; }

input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0.5) inset;
  color: white !important; }

