@import "../../_variables.scss";

.login {

    max-width: 600px;
    margin: 20px auto 0px auto;
    padding: 0px 80px;

    h2 {
        font-family: 'Cube Regular', 'Exo', 'sans-serif';
        font-weight: 100;
        font-size: 22px;
    }

    .closeButton {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 22px;
        cursor: pointer;
    }

    .forgotPassword {
        margin-top: 10px;
        color: $color-blue;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
    }

    .error {
        color: $color-red;
    }

}
