@import "../../_variables.scss";

.checkBox {
    text-align: left;
    margin-bottom: 32px;
    display: table;

    @include tablet {
        margin-bottom: 0px;
    }

    input {
        display: table-cell;
        margin-right: 16px;
        height: 32px;
        width: 32px;
        position: relative;
        top: 8px;

        @include tablet {
            margin-right: 8px;
            height: 20px;
            width: 20px;
            top: 6px;
        }
    }

    label {
        font-size: 20px;
        display: table-cell;

        a {
            color: $color-blue;
        }
        
        @include tablet {
            font-size: 13px;
            line-height: 15px;
        }
    }
}
